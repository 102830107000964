$btn-white-space: nowrap;
$primary: #b4b5b7;
$border-radius: 0.25rem;
$font-family: var(--bs-body-font-family);
$theme-colors: (
  
	'accent': #148ca7,
	'primary': #b4b5b7,
	'primary-dark': #00363a,
	'primary-light': #148ca7,
	'primary-text': #212121,
	'secondary-text': #757575,
	'text-on-primary': #ffffff,
	'text-on-accent': #ffffff,
	'danger': #dc3545,
	'info': #17a2b8,
	'success': #28a745,
	'warning': #fce359e0,
	'dark': #343a40,
	'light': #f8f9fa,
	'blue': #007bff,
	'cyan': #17a2b8,
	'gray': #6c757d,
	'green': #28a745,
	'indigo': #6610f2,
	'orange': #fd7e14,
	'pink': #e83e8c,
	'purple': #6f42c1,
	'red': #dc3545,
	'teal': #20c997,
	'white': #ffffff,
	'yellow': #ffc107,
	'whitetrans': #ffffff,
	'degrauno': #1b5e20,
	'trasparente': #ffffff00,
	'mi': #91bf2c,
) !default;
