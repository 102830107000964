.f-20px {
  font-size: 20px;
}

.f-28px {
  font-size: 28px;
}

.f-30px {
  font-size: 30px;
}

.f-40px {
  font-size: 40px;
}

.f-small {
  font-size: small;
}

.f-bold {
  font-weight: bold;
}

.text-uppercase {
  text-transform: uppercase;
}


@font-face {
	font-family: russooneregular;
	src: url('../../assets/fonts/RussoOne-Regular.ttf');
}

.ff-russooneregular {
font-family: russooneregular;
}

@font-face {
	font-family: gantaribold;
	src: url('../../assets/fonts/Gantari-Bold.ttf');
}

.ff-gantaribold {
font-family: gantaribold;
}

@font-face {
	font-family: gantariregular;
	src: url('../../assets/fonts/Gantari-Regular.ttf');
}

.ff-gantariregular {
font-family: gantariregular;
}

@font-face {
	font-family: monofontorg;
	src: url('../../assets/fonts/monofonto_rg.otf');
}

.ff-monofontorg {
font-family: monofontorg;
}

@font-face {
	font-family: renner;
	src: url('../../assets/fonts/Renner.ttf');
}

.ff-renner {
font-family: renner;
}

@font-face {
	font-family: sonosemibold;
	src: url('../../assets/fonts/Sono-SemiBold.ttf');
}

.ff-sonosemibold {
font-family: sonosemibold;
}

@font-face {
	font-family: sonoregular;
	src: url('../../assets/fonts/Sono-Regular.ttf');
}

.ff-sonoregular {
font-family: sonoregular;
}

@font-face {
	font-family: sonomedium;
	src: url('../../assets/fonts/Sono-Medium.ttf');
}

.ff-sonomedium {
font-family: sonomedium;
}

@font-face {
	font-family: sonolight;
	src: url('../../assets/fonts/Sono-Light.ttf');
}

.ff-sonolight {
font-family: sonolight;
}

@font-face {
	font-family: sonoextralight;
	src: url('../../assets/fonts/Sono-ExtraLight.ttf');
}

.ff-sonoextralight {
font-family: sonoextralight;
}

@font-face {
	font-family: sonoextrabold;
	src: url('../../assets/fonts/Sono-ExtraBold.ttf');
}

.ff-sonoextrabold {
font-family: sonoextrabold;
}

@font-face {
	font-family: sonobold;
	src: url('../../assets/fonts/Sono-Bold.ttf');
}

.ff-sonobold {
font-family: sonobold;
}

@font-face {
	font-family: nunitovariablefontwght;
	src: url('../../assets/fonts/Nunito-VariableFont_wght.ttf');
}

.ff-nunitovariablefontwght {
font-family: nunitovariablefontwght;
}

@font-face {
	font-family: hurmegeometricsans3black;
	src: url('../../assets/fonts/HurmeGeometricSans3Black.otf');
}

.ff-hurmegeometricsans3black {
font-family: hurmegeometricsans3black;
}

@font-face {
	font-family: solidsoldier;
	src: url('../../assets/fonts/solid_soldier.ttf');
}

.ff-solidsoldier {
font-family: solidsoldier;
}

@font-face {
	font-family: overload;
	src: url('../../assets/fonts/Overload.otf');
}

.ff-overload {
font-family: overload;
}

@font-face {
	font-family: aileronbold;
	src: url('../../assets/fonts/Aileron-Bold.otf');
}

.ff-aileronbold {
font-family: aileronbold;
}



.f-12px {
	font-size: 12px;
}

.f-15px {
	font-size: 15px;
}

.f-10px {
	font-size: 10px;
}

.f-25px {
	font-size: 25px;
}

.f-18px {
	font-size: 18px;
}


