.w-auto {
	width: auto !important;
}

.w-fill {
	width: 0;
	flex-grow: 1 !important;
}

.w-100 {
	width: 100% !important;
}

.max-w-100px {
	max-width: 100px !important;
}

.max-w-900px {
	max-width: 900px !important;
}

.w-90 {
	width: 90% !important;
}

.w-15 {
	width: 15% !important;
}

.max-w-350px {
	max-width: 350px !important;
}

.min-w-300px {
	min-width: 300px !important;
}

.max-w-800px {
	max-width: 800px !important;
}

.w-60px {
	width: 60px !important;
}

.max-w-450px {
	max-width: 450px !important;
}

.min-w-320px {
	min-width: 320px !important;
}

.max-w-400px {
	max-width: 400px !important;
}

.max-w-1800px {
	max-width: 1800px !important;
}

.max-w-200px {
	max-width: 200px !important;
}

.w-50 {
	width: 50% !important;
}

.max-w-1200px {
	max-width: 1200px !important;
}

.w-95 {
	width: 95% !important;
}

.w-5 {
	width: 5% !important;
}

.max-w-500px {
	max-width: 500px !important;
}

.min-w-250px {
	min-width: 250px !important;
}

.min-w-600px {
	min-width: 600px !important;
}

.w-40 {
	width: 40% !important;
}

.w-80 {
	width: 80% !important;
}

.min-w-330px {
	min-width: 330px !important;
}

.w-75 {
	width: 75% !important;
}

.w-50px {
	width: 50px !important;
}

.w-5px {
	width: 5px !important;
}

.w-8 {
	width: 8% !important;
}

.max-w-600px {
	max-width: 600px !important;
}

.w-70 {
	width: 70% !important;
}

.w-30 {
	width: 30% !important;
}

.max-w-150px {
	max-width: 150px !important;
}

