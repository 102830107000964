.p-10px {
	padding: 10px;
}

.p-20px {
	padding: 20px;
}

.p-1rem {
	padding: 10px;
}

.px-1_5rem {
	padding-left: 1.5rem;
	padding-right:1.5rem;
}

.py-1rem {
	padding-top: 1rem;
	padding-bottom:1rem;
}

.p-12px {
	padding: 12px;
}

.p-3px {
	padding: 3px;
}

.p-40px {
	padding: 40px;
}

.p-8px {
	padding: 8px;
}

.pe-4px {
	padding-right: 4px;
}

.py-8px {
	padding-top: 8px;
	padding-bottom: 8px;
}

.py-32px {
	padding-top: 32px;
	padding-bottom: 32px;
}

.p-32px {
	padding: 32px;
}

.pe-8px {
	padding-right: 8px;
}

.p-15px {
	padding: 15px;
}

.p-16px {
	padding: 16px;
}

.p-30px {
	padding: 30px;
}

.p-5px {
	padding: 5px;
}

.p-24px {
	padding: 24px;
}

.p-25px {
	padding: 25px;
}

.pe-25px {
	padding-right: 25px;
}

.pt-25px {
	padding-top: 25px;
}

.pe-15px {
	padding-right: 15px;
}

.px-20px {
	padding-left: 20px;
	padding-right: 20px;
}

.pt-5px {
	padding-top: 5px;
}

.pb-20px {
	padding-bottom: 20px;
}

