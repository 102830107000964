.mx-auto {
	margin-left: auto;
	margin-right: auto;
}

.my-auto {
	margin-top: auto;
	margin-bottom: auto;
}

.mt-15px {
	margin-top: 15px;
}

.me-20px {
	margin-right: 20px;
}

.ms-20px {
	margin-left: 20px;
}

.m-20px {
	margin: 20px;
}

.m-10px {
	margin: 10px;
}

.me-600px {
	margin-right: 600px;
}

.m-80px {
	margin: 80px;
}

.mt-20px {
	margin-top: 20px;
}

.mb-100px {
	margin-bottom: 100px;
}

.mt-24px {
	margin-top: 24px;
}

.mt-16px {
	margin-top: 16px;
}

.mt-32px {
	margin-top: 32px;
}

.me-30px {
	margin-right: 30px;
}

.mb-30px {
	margin-bottom: 30px;
}

.m-12px {
	margin: 12px;
}

.ms-24px {
	margin-left: 24px;
}

.me-48px {
	margin-right: 48px;
}

.mb-15px {
	margin-bottom: 15px;
}

.ms-15px {
	margin-left: 15px;
}

.me-24px {
	margin-right: 24px;
}

.mb-20px {
	margin-bottom: 20px;
}

.m-5px {
	margin: 5px;
}

.mt-40px {
	margin-top: 40px;
}

.mt-5px {
	margin-top: 5px;
}

.mt-10px {
	margin-top: 10px;
}

.me-15px {
	margin-right: 15px;
}

.mt-8px {
	margin-top: 8px;
}

.mx-10px {
	margin-left: 10px;
	margin-right: 10px;
}

.mb-25px {
	margin-bottom: 25px;
}

.mt-48px {
	margin-top: 48px;
}

.m-32px {
	margin: 32px;
}

.mt-50px {
	margin-top: 50px;
}

.me-10px {
	margin-right: 10px;
}

.mb-5px {
	margin-bottom: 5px;
}

.m-15px {
	margin: 15px;
}

.my-20px {
	margin-top: 20px;
	margin-bottom: 20px;
}

.my-15px {
	margin-top: 15px;
	margin-bottom: 15px;
}

.mb-10px {
	margin-bottom: 10px;
}

